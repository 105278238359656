<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl+'/'+this.$route.params.id+'/log'"
        :fields="fields"
        :title="title"
        :is-view="false"
        :is-edit="false"
        :is-add="false"
        :is-delete="false"
      >

        <template #cell(created_at)="data">
          {{ data.item.created_at | moment('DD-MM-YYYY HH:ss') }}
        </template>

      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import {
  BCard
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      dataurl:"/user",
      title:"Log User",
      fields: [
        { key: 'created_at', label: 'Tanggal', sortable: true },
        { key: 'subject_type', label: 'Subject', sortable: true },
        { key: 'description', label: 'Keterangan', sortable: true }
      ],
    }
  },
}
</script>
<style lang="">

</style>
