<template>
  <b-card>
    <base-info
      :geturl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      title-icon="UsersIcon"
      title="Detail Info"
      :isdelete="true"
      :isedit="true"
    />
  </b-card>
</template>

<script>

import {BCard} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'

export default {
  components: {
    BCard,
    BaseInfo
  },

  data() {
    return {
      dataurl:"/user",
      baseroute:"user",
      fields: [
        { key: 'email', label: 'Username', bold:true},
        { key: 'name', label: 'Nama' },
        { key: 'role.name', label: 'Level'},
        { key: 'region', label: 'Region'},
        { key: 'last_login_at', label: 'Last Login'},
        { key: 'is_active', label: 'Status', type:'span', data:{true:'Aktif', false:'Tidak Aktif'}}
      ],
    }
  },
}
</script>
