<template>
  <b-tabs
    vertical
    nav-wrapper-class="nav-vertical"
  >
    <b-tab active>
      <template #title>
        <feather-icon icon="UserCheckIcon" />
        User Info
      </template>
      <info />
    </b-tab>
    <!-- <b-tab >
      <template #title>
        <feather-icon icon="LockIcon" />
        Ganti Password
      </template>
      <strong>Change Password</strong>
    </b-tab> -->
    <b-tab>
      <template #title>
        <feather-icon icon="ShieldIcon" />
        Log
      </template>
      <log />
    </b-tab>
  </b-tabs>
</template>

<script>

import {BRow,BTabs, BTab} from 'bootstrap-vue'
import Info from './include/Info.vue'
import Log from './include/Log.vue'

export default {
  components: {
    BRow,
    BTabs, 
    BTab,
    Info,
    Log
  },
}
</script>
<style>
[dir] .nav-vertical .nav.nav-tabs .nav-item .nav-link {
  margin-bottom: 0; 
  justify-content: left;
}
</style>
